import Grid from '@mui/material/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { getStatusIconForString } from '../../../utils/statusIcons';
import { useTranslate } from '../../../customHooks';

const useStyles = makeStyles((theme) => ({
    gridStyles: {
        padding: '0 !important',
        display: 'flex',
        '& a:nth-child(1)': {
          paddingRight: '10px',
        },
        minHeight: '50px',
    },
    modalLabel: {
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '16px',
        color: '#707070',
        width: '50%',
        '& a:nth-child(last)': {
            paddingBottom: 0,
        },
    },
    modalValue: {
        fontFamily: 'Lato !important',
        fontStyle: 'normal !important',
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '20px',
        color: '#333333',
        '& a:nth-child(last)': {
            paddingBottom: 0,
        },
    },
    commitStatusImage: {
        marginRight: '4px'
    },
}));

export const AssociatedStatusesModalBody = (props: any) => {
  const { firewallStatus, firewallCommitStatus, endpointStatus, ruleStackStatus, globalRuleStackStatus, deviceRuleStackCommitStatus } = props;
  const classes = useStyles();
  const translate = useTranslate();

  return (
    <>
        <Grid container rowSpacing={2} columnSpacing={2} className={classes.gridStyles}>
            <Grid item xs={5} className={classes.modalLabel}>{translate(`resources.firewalls.modal.firewallStatus`)}:</Grid>
            <Grid item xs={5} className={classes.modalValue}>
                {firewallStatus !== undefined && <img className={classes.commitStatusImage} src={getStatusIconForString(firewallStatus)} alt="Status" />}
                <span>{firewallStatus?.toUpperCase()}</span>
            </Grid>
        </Grid>
        <Grid container rowSpacing={2} columnSpacing={2} className={classes.gridStyles}>
            <Grid item xs={5} className={classes.modalLabel}>{translate(`resources.firewalls.modal.firewallCommitStatus`)}:</Grid>
            <Grid item xs={5} className={classes.modalValue}>
                {firewallCommitStatus !== undefined && <img className={classes.commitStatusImage} src={getStatusIconForString(firewallCommitStatus)} alt="Status" />}
                <span>{firewallCommitStatus?.toUpperCase()}</span>
            </Grid>
        </Grid>
        <Grid container rowSpacing={2} columnSpacing={2} className={classes.gridStyles}>
            <Grid item xs={5} className={classes.modalLabel}>{translate(`resources.firewalls.modal.deviceRuleStackCommitStatus`)}:</Grid>
            <Grid item xs={5} className={classes.modalValue}>
                {deviceRuleStackCommitStatus !== undefined && <img className={classes.commitStatusImage} src={getStatusIconForString(deviceRuleStackCommitStatus)} alt="Status" />}
                <span>{deviceRuleStackCommitStatus?.toUpperCase()}</span>
            </Grid>
        </Grid>
        <Grid container rowSpacing={2} columnSpacing={2} className={classes.gridStyles}>
            <Grid item xs={5} className={classes.modalLabel}>{translate(`resources.firewalls.modal.localRulestackStatus`)}:</Grid>
            <Grid item xs={5} className={classes.modalValue}>
                {ruleStackStatus !== undefined && <img className={classes.commitStatusImage} src={getStatusIconForString(ruleStackStatus)} alt="Status" />}
                <span>{ruleStackStatus !== undefined ? ruleStackStatus?.toUpperCase() : translate("resources.overviewPanel.na")}</span>
            </Grid>
        </Grid>
        <Grid container rowSpacing={2} columnSpacing={2} className={classes.gridStyles}>
            <Grid item xs={5} className={classes.modalLabel}>{translate(`resources.firewalls.modal.globalRulestackStatus`)}:</Grid>
            <Grid item xs={5} className={classes.modalValue}>
                {globalRuleStackStatus !== undefined && <img className={classes.commitStatusImage} src={getStatusIconForString(globalRuleStackStatus)} alt="Status" />}
                <span>{globalRuleStackStatus !== undefined ? globalRuleStackStatus?.toUpperCase() : translate("resources.overviewPanel.na")}</span>
            </Grid>
        </Grid>
        <Grid container rowSpacing={2} columnSpacing={2} className={classes.gridStyles}>
            <Grid item xs={5} className={classes.modalLabel}>{translate(`resources.firewalls.modal.endpointStatus`)}:</Grid>
            <Grid item xs={5} className={classes.modalValue}>
                {endpointStatus !== undefined && <img className={classes.commitStatusImage} src={getStatusIconForString(endpointStatus)} alt="Status" />}
                <span>{endpointStatus?.toUpperCase()}</span>
            </Grid>
        </Grid>
    </>
  );
}

export default AssociatedStatusesModalBody;
