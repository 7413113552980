import { FormControl, Typography, Grid, Box } from "@material-ui/core";
import cx from "classnames";
import { Button, Link } from "@panwds/react-ui";
import { createTheme, makeStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { CloseOutlined, CheckOutlined } from "@material-ui/icons";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Field, withTypes } from "react-final-form";
import { toast } from '../components';
import * as DataTypes from "../api/FwaasDataTypes";
import { PANWDSInput } from "../components/PANWDSElements";
import { PANWDSSelect } from "../components/PANWDSElements";
import { Row } from "../components/FormElements";
import { dataProvider } from "../dataProvider";
import { authProvider, oktaProvider } from "../authProvider";
import { ApplicationConfigManager } from "../types";
import { Background } from "./styles";
import { lightTheme } from "./themes";
import { useTranslate } from '../customHooks';
import { composeValidators } from '../utils/validate';

import {
    isBlockedDomain,
    isEmail,
    isRequired,
} from "../utils/validate";
import "./CustomPanwdsCard.css";
import CircularLoader from "../components/CircularLoader/CircularLoader";
import { pendoInitialize, pendoUpdate } from "./Pendo";
import getUrlParameter from "../utils/GetUrlParameter";
import queryString from "query-string";
import { AuthCustomCard } from "./components";
import { RouteUri } from "../routeUri";
import { isEmpty } from "lodash";
import { useHistory, useLocation } from "react-router-dom";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme) => ({
    main: {
        display: "flex",
        flexDirection: "column",
        //minHeight: "100vh",
        color: "#333333",
        alignItems: "center",
        justifyContent: "flex-start",
        //backgroundImage: `linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 35%, rgba(255,255,255,1) 100%), url("${BackgroundImage}")`,
        //backgroundPosition: "center",
        //backgroundRepeat: "no-repeat",
        //backgroundSize: "cover",
    },
    loadingBlur: {
        filter: "blur(1px)",
    },
    headerBg: {
        minWidth: "100%",
    },
    customCard: {
        minWidth: "100%",
        margin: "0",
        paddingTop: "0",
        minHeight: "auto",
    },
    avatar: {
        margin: "1em",
        display: "flex",
        justifyContent: "center",
    },
    icon: {
        backgroundColor: theme.palette.secondary.main,
    },
    logo: {
        padding: "15px",
    },
    title: {
        fontWeight: 400,
        fontSize: 24,
        color: "#333333",
        textAlign: "center",
        paddingTop: 20,
        flex: 0,
    },
    recomendationsTitle: {
        color: "#707070",
        fontSize: "14px",
        fontWeight: 700,
        textAlign: "left",
        marginBottom: "16px",
    },
    recomendation: {
        color: "#707070",
        fontSize: "14px",
        fontWeight: 400,
        textAlign: "left",
        display: "flex",
        alignItems: "center",
    },
    hint: {
        marginTop: "1em",
        display: "flex",
        justifyContent: "center",
        color: theme.palette.grey[500],
    },
    form: {
        paddingBottom: "1em",
    },
    formControl: {
        marginTop: "1em",
        width: "100%",
    },
    pwd: {
        color: "#006FCC",
        cursor: "pointer",
        textAlign: "center",
    },
    link: {
        textAlign: "left",
        marginRight: "auto",
        textTransform: "none",
        cursor: "pointer",
        color: "#006FCC",
        fontSize: "14px",
        fontWeight: 700,
    },
    buttonRight: {
        textAlign: "right",
        marginLeft: "auto",
        textTransform: "none",
    },
    actions: {
        flexDirection: "row-reverse",
    },
    dashedLineDiv: {
        width: "100%",
        textAlign: "center",
        borderBottom: "1px solid #DADBDB",
        lineHeight: "0.1em",
        margin: "10px 0 20px",
        "& > span": {
            background: "#F9F9F9",
            color: "#707070",
            padding: "0 10px",
        },
    },
    subTitle: {
        display: "block",
        width: "350px"
    }
}));
interface FormValues {
    UserName?: string;
    RegistrationToken?: string;
    email?: string;
}

interface FormErrors extends FormValues {
    range?: string;
    upper?: string;
    lower?: string;
    number?: string;
    special?: string;
}

const { Form } = withTypes<FormValues>();

const SignUp = () => {
    const targetOrigin = "https://aws.amazon.com";
    //const appConfigRegion = ApplicationConfigManager.getInstance().getConfig().currentRegion;

    const [loading, setLoading] = useState(false);
    const [globalLoading, setGlobalLoading] = useState(false);
    const [formStatus, setFormStatus] = useState("create"); // create || activate || linkTenant || multiTenant || linkTenantSuccessful || linkAccount
    const [existingTenant, setExistingTenant] = useState(false);
    const translate = useTranslate();
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    const [tenantChoices, setTenantChoices] = useState<
        any[] | undefined
    >([]);

    useEffect(() => {
        if (
            getUrlParameter("en-link-acc") !== "" &&
            getUrlParameter("en-link-acc") !== "False"
        ) {
            setFormStatus("linkTenant");
        }
        pendoInitialize({});
        if (!isEmpty(location.state) && !isEmpty(location.state.tokens)) {
            setFormStatus("linkAccount");
            handleLogin(location.state);
        }
    }, []);

    const handleSubmit = async (params: any = {}) => {
        const postUrl =
            ApplicationConfigManager.getInstance().getAPIBaseUri() +
            "/mgmt/subscribe";
        const RegistrationToken = getUrlParameter("x-amzn-marketplace-token");

        if (!RegistrationToken) {
            toast.error(
                "Registration Token Missing. Please go to AWS Marketplace and follow the instructions to set up your account!"
            );
        } else {
            params["RegistrationToken"] = getUrlParameter("x-amzn-marketplace-token");
            setLoading(true);
            dataProvider
                .create("subscription", { data: params })
                .then(async (response: DataTypes.IFwaasApiResponse) => {
                    if (response.data) {
                        pendoUpdate({ ...params });
                        if (response?.data?.status < 200 || response?.data?.status >= 300) {
                            //@ts-ignore
                            toast.error(response?.ResponseStatus?.Reason);
                            setLoading(false);

                        } else {
                            setLoading(false);
                            if(response.data.PanwidEmailSent === false){
                                const options = {
                                    state: queryString.parse(window?.location?.search)
                                };
                                oktaProvider.signIn(options);
                            }
                            else {
                                setFormStatus("activate");                                
                            }
                        }
                    } else {
                        toast.error(response?.error?.error);
                        setLoading(false);
                    }
                })
                .catch((e: any) => {
                    toast.error(e?.error?.error);
                    setLoading(false);
                });
        }
    };

    const handleLogin = async (state: any) => {
        const { queryString, tokens, idpC } = state;
        setGlobalLoading(true);
        if (idpC === true) {
            localStorage.setItem('idToken', tokens);
        } else {
            await oktaProvider.setToken({ tokens });
        }
        await dataProvider
            .get("subscription")
            .then(async (response: DataTypes.IFwaasApiResponse) => {
                if (response.data) {
                    setGlobalLoading(false);
                    if (response.data.length > 1) {
                        setTenantChoices(
                            response.data.map((data: any) => ({
                                text: (data?.CspSerialNumber) ? `${data?.LinkAccountId} (CSP: ${data?.CspSerialNumber})` : data?.LinkAccountId,
                                value: data?.SubscriptionKey,
                            }))
                        );
                        setFormStatus("multiTenant")
                    }
                    else {
                        if (response.data[0] && response.data[0].SubscriptionKey) {
                            var newAppConfig =
                                ApplicationConfigManager.getInstance().getConfig();
                            newAppConfig.xApiKey = response?.data[0]?.SubscriptionKey;
                            await ApplicationConfigManager.getInstance().setConfig(
                                newAppConfig,
                                true
                            );
                        }
                        await accountOnboarding(state);

                    }
                } else {
                    toast.error(response?.error?.error);
                    await authProvider.logout(null);
                    history.push(RouteUri.Login);
                }
            });
    }

    const handleTenantSelection = async (values: any) => {

        var newAppConfig = ApplicationConfigManager.getInstance().getConfig();
        let tenant = values?.TenantId;
        newAppConfig.xApiKey = tenant;
        await ApplicationConfigManager.getInstance().setConfig(
            newAppConfig,
            true
        );

        await accountOnboarding(location.state);

    }

    const accountOnboarding = async (state: any) => {
        const AWSMarketplaceToken = state?.queryString["x-amzn-marketplace-token"] || "";
        const ProductId = state?.queryString["x-amzn-marketplace-product-id"] || "";
        const AgreementId = state?.queryString["x-amzn-marketplace-agreement-id"] || "";
        const AccoutId = state?.queryString["acc-id"] || ""

        let data: any = {
            Existing: existingTenant,
            Origin: "AwsMarketplace",
            AWSMarketplaceToken: AWSMarketplaceToken,
            ProductId: ProductId,
            AgreementId: AgreementId,
            AccountId: AccoutId
        };
        if (AccoutId && ProductId && AgreementId) {
            setLoading(true);
            await dataProvider.create("accounts", { data })
                .then((acctResponse) => {
                    if (!acctResponse?.data) {
                        toast.error(response?.error, { toastId: "accounts-create" });
                    } else {
                        setFormStatus("linkTenantSuccessful")
                    }
                })
                .catch(async (e: any) => {
                    toast.error(e?.error);
                    await authProvider.logout(null);
                    history.push(RouteUri.Login);
                }).finally(() => {
                    setLoading(false);
                })
        }
        else {
            toast.error(
                translate("auth.linkAccountError")
            );
            await authProvider.logout(null);
            setGlobalLoading(false);
        }
    }

    const submitMap = {
        create: handleSubmit,
        linkTenant: () => { },
        activate: () => { },
        multiTenant: handleTenantSelection,
        linkTenantSuccessful: () => { },
        linkAccount: () => { }
    };

    const validateMap = {
        create: undefined,
        linkTenant: undefined,
        activate: undefined,
        multiTenant: undefined,
        linkTenantSuccessful: undefined,
        linkAccount: undefined
    };

    const formTitleMap = {
        create: "auth.title",
        linkTenant: "linkTenant.title",
        multiTenant: "multiTenantModal.title",
        activate: "activate.title",
        linkTenantSuccessful: "linkTenantSuccessful.title",
        linkAccount: "linkTenant.title"
    }

    if (globalLoading) {
        return (
            <CircularLoader loading={globalLoading} />
        )
    }

    return (
        <>
            {/* @ts-expect-error Server Component */}
            <Background>
                <AuthCustomCard
                    loading={loading}
                    title={translate(formTitleMap[formStatus as keyof typeof formTitleMap])}>

                    <Form
                        onSubmit={submitMap[formStatus as keyof typeof submitMap]}
                        validate={validateMap[formStatus as keyof typeof submitMap]}
                        render={({ handleSubmit, errors, ...formProps }) => (
                            <form onSubmit={handleSubmit}>
                                {formStatus === "linkTenant" && (
                                    <>
                                        <div className={loading ? classes.loadingBlur : ""}>
                                            <Row>
                                                <Grid
                                                    container
                                                    justifyContent={"center"}
                                                    style={{ padding: "24px" }}
                                                >
                                                    <Button
                                                        appearance="primary"
                                                        size="md"
                                                        style={{ textTransform: "none" }}
                                                        color="primary"
                                                        data-metrics="cloudngfw-sign-up-link-exisitng-tenant-button"
                                                        type="submit"
                                                        disabled={loading}
                                                        onClick={
                                                            () => {
                                                                setExistingTenant(true);
                                                                history.push({
                                                                    pathname: RouteUri.Login,
                                                                    search: location.search
                                                                })
                                                                //history.replace(pathname: RouteUri.Login, search: queryString.parse(window?.location?.search))
                                                            }
                                                        }
                                                    >
                                                        {translate("linkTenant.existingTenant")}
                                                    </Button>
                                                </Grid>
                                            </Row>
                                            <Row>
                                                <Grid
                                                    container
                                                    justifyContent={"center"}
                                                    style={{ padding: "24px" }}
                                                >
                                                    <div className={classes.dashedLineDiv}>
                                                        <span>{translate("linkTenant.newTenantTitle")}</span>
                                                    </div>
                                                </Grid>
                                            </Row>
                                            <Row>
                                                <Grid
                                                    container
                                                    justifyContent={"center"}
                                                    style={{ padding: "24px" }}
                                                >
                                                    <Button
                                                        appearance="secondary"
                                                        size="md"
                                                        style={{ textTransform: "none" }}
                                                        color="primary"
                                                        data-metrics="cloudngfw-sign-up-link-new-tenant-button"
                                                        type="submit"
                                                        disabled={loading}
                                                        onClick={() => setFormStatus("create")}
                                                    >
                                                        {translate("linkTenant.newTenant")}
                                                    </Button>
                                                </Grid>
                                            </Row>
                                        </div>
                                    </>
                                )}
                                {formStatus === "create" && (
                                    <>
                                        <div className={classes.form}>
                                            <FormControl className={classes.formControl}>
                                                <Field
                                                    autoFocus
                                                    name="UserName"
                                                    type="email"
                                                    title={translate("auth.email")}
                                                    dataMetrics="cloudngfw-sign-up-email"
                                                    disabled={loading}
                                                    required
                                                    // @ts-ignore
                                                    component={PANWDSInput}
                                                    validate={composeValidators(
                                                        isRequired,
                                                        isBlockedDomain,
                                                        isEmail
                                                    )}
                                                />
                                            </FormControl>
                                            <Grid container spacing={2}>
                                                <Grid item xs={6}>
                                                    <FormControl className={classes.formControl}>
                                                        <Field
                                                            title={translate("auth.name")}
                                                            name="FirstName"
                                                            disabled={loading}
                                                            dataMetrics="cloudngfw-sign-up-first-name"
                                                            // @ts-ignore
                                                            component={PANWDSInput}
                                                            validate={composeValidators(isRequired)}
                                                            required
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <FormControl className={classes.formControl}>
                                                        <Field
                                                            name="LastName"
                                                            title={translate("auth.lastName")}
                                                            dataMetrics="cloudngfw-sign-up-last-name"
                                                            disabled={loading}
                                                            // @ts-ignore
                                                            component={PANWDSInput}
                                                            validate={composeValidators(isRequired)}
                                                            required
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </div>
                                        <div className="tw-flex tw-items-center tw-justify-end">
                                            <Link
                                                data-metrics="cloudngfw-sign-up-login-link"
                                                className={classes.link}
                                                onClick={
                                                    () => {
                                                        history.push({
                                                            pathname: RouteUri.Login,
                                                            search: location.search
                                                        })
                                                    }
                                                }
                                            >
                                                {translate(`auth.link`)}
                                            </Link>

                                            <Button
                                                appearance="primary"
                                                size="md"
                                                data-metrics="cloudngfw-sign-up-create-button"
                                                type="submit"
                                                disabled={loading}
                                                style={{ textTransform: "none" }}
                                            >
                                                {translate(`auth.button`)}
                                            </Button>

                                        </div>
                                    </>
                                )}
                                {formStatus === "activate" && (
                                    <>
                                        <div className={loading ? classes.loadingBlur : ""}>
                                            <Row>
                                                <Grid
                                                    container
                                                    justifyContent={"center"}
                                                    style={{ padding: "24px" }}
                                                >
                                                    <div>
                                                        <span className={classes.subTitle}>
                                                            <FormattedMessage
                                                                id={"activate.message"}
                                                                values={{
                                                                    break: <br />,
                                                                    link: <Link
                                                                        dataMetrics="cloudngfw-get-login-help"
                                                                        external
                                                                        href="https://www.paloaltonetworks.com/services/support/login-assistance"
                                                                    >
                                                                        {translate(`activate.getHelp`)}
                                                                    </Link>
                                                                }} />
                                                        </span>
                                                    </div>
                                                </Grid>
                                            </Row>
                                            <Row>
                                                <Grid
                                                    container
                                                    justifyContent={"center"}
                                                    style={{ padding: "24px" }}
                                                >
                                                    <Button
                                                        appearance="primary"
                                                        size="md"
                                                        style={{ textTransform: "none" }}
                                                        data-metrics="cloudngfw-sign-up-link-new-tenant-button"
                                                        type="submit"
                                                        disabled={loading}
                                                        onClick={
                                                            () => {
                                                                const options = {
                                                                    state: queryString.parse(window?.location?.search)
                                                                };
                                                                oktaProvider.signIn(options);
                                                            }
                                                        }
                                                    >
                                                        {translate("activate.login")}
                                                    </Button>
                                                </Grid>
                                            </Row>
                                        </div>
                                    </>
                                )}
                                {formStatus === "multiTenant" && (
                                    <>
                                        <div className={classes.form}>
                                            <FormControl className={classes.formControl}>
                                                <Field
                                                    name="TenantId"
                                                    // @ts-ignore
                                                    component={PANWDSSelect}
                                                    items={tenantChoices}
                                                    dataMetrics="cloudngfw-signup-multitenant-tenant"
                                                    loading={!tenantChoices}
                                                />
                                            </FormControl>
                                        </div>
                                        <div className="tw-flex tw-items-center tw-justify-end">
                                            <Button
                                                appearance="primary"
                                                size="md"
                                                color="primary"
                                                data-metrics="cloudngfw-sign-up-create-button"
                                                type="submit"
                                                disabled={loading}
                                                style={{ textTransform: "none" }}
                                            >
                                                {translate(`auth.continue`)}
                                            </Button>
                                        </div>
                                    </>
                                )}
                                {formStatus === "linkTenantSuccessful" && (
                                    <>
                                        <div className={loading ? classes.loadingBlur : ""}>
                                            <Row>
                                                <Grid
                                                    container
                                                    justifyContent={"center"}
                                                    style={{ padding: "24px" }}
                                                >
                                                    <div>
                                                        <span className={classes.subTitle}>{translate("linkTenantSuccessful.subTitle")}</span>
                                                    </div>
                                                </Grid>
                                            </Row>
                                            <Row>
                                                <Grid
                                                    container
                                                    justifyContent={"center"}
                                                >
                                                    <Button
                                                        appearance="primary"
                                                        size="md"
                                                        style={{ textTransform: "none" }}
                                                        data-metrics="cloudngfw-sign-up-link-tenant-finishSubscription-button"
                                                        type="submit"
                                                        disabled={loading}
                                                        onClick={
                                                            async () => {
                                                                const mktPlaceUrl = "https://aws.amazon.com/marketplace/saas/configuration"
                                                                const productId = location.state?.queryString["x-amzn-marketplace-product-id"];
                                                                const productUrl = (productId) ? mktPlaceUrl + "?productId=" + productId : mktPlaceUrl
                                                                await authProvider.logout(null);
                                                                window.open(productUrl, '_self');
                                                            }
                                                        }
                                                    >
                                                        {translate("linkTenantSuccessful.finishSubscription")}
                                                    </Button>
                                                </Grid>
                                            </Row>
                                        </div>
                                    </>
                                )}
                                {formStatus === "linkAccount" && (
                                    <>
                                        <div>
                                            <Row>
                                                <Grid
                                                    container
                                                    justifyContent={"center"}
                                                    style={{ padding: "24px" }}
                                                >
                                                    <div>
                                                        <span className={classes.subTitle}>
                                                        {translate("linkTenant.message")}
                                                        </span>
                                                    </div>
                                                </Grid>
                                            </Row>
                                        </div>
                                    </>
                                )}
                            </form>
                        )}
                    />

                </AuthCustomCard>
            </Background >
        </>
    );
};

SignUp.propTypes = {
    authProvider: PropTypes.func,
    previousRoute: PropTypes.string,
};

// We need to put the ThemeProvider decoration in another component
// Because otherwise the useStyles() hook used in SignUp won't get
// the right theme
const SignUpWithTheme = (props: any) => (
    <ThemeProvider theme={createTheme(lightTheme)}>
        <SignUp {...props} />
    </ThemeProvider>
);

export default SignUpWithTheme;
