import {PANWDSInput, PanwdsTableLightWithFilters} from "../../../../components/PANWDSElements";
import {useTranslate} from "../../../../customHooks";
import {FormControl, Grid} from "@material-ui/core";
import {Field, useForm, useFormState} from "react-final-form";
import * as React from "react";
import {useMemo} from "react";
import {Row} from "../../../../components/FormElements";
import {nameStyleCursor} from '../../../../layout/styles';
import {handleManageEgressNatTraffic} from "./ManageEgressNatTraffic";
import {isEmpty} from "lodash";
import {Button} from "@panwds/react-ui";

const Index = () => {
    const translate = useTranslate();
    const nameClass = nameStyleCursor();
    const form = useForm();
    const formState = useFormState();

    const checkEndpointChanges = (item: any) => {
        const Features = formState?.values?.Features || {};
        if (formState?.values?.Firewall?.SecurityZones) {
            const securityZone = formState?.values?.Firewall?.SecurityZones.find((sz: any) => sz.EndpointId === item.EndpointId);
            if (securityZone) {
                item = {...item, ...securityZone}
            }
        }
        if (Features && Features?.SecurityZones) {
            const securityZone = Features?.SecurityZones.find((sz: any) => sz.EndpointId === item.EndpointId);
            if (securityZone) {
                item = {...item, ...securityZone}
            }
        }
        return item;
    }

    let columns: any = [
        {
            accessor: 'EndpointId',
            Header: translate(`resources.firewalls.fields.EndpointId`),
            columnSize: "auto",
            Cell: ({row}: any) => <span>{row?.original?.EndpointId}</span>,
        },
        {
            accessor: 'AccountId',
            Header: translate(`resources.firewalls.fields.AccountId`),
            columnSize: "auto",
            Cell: ({row}: any) => <span>{row?.original?.AccountId}</span>,
        },
        {
            accessor: 'VpcId',
            Header: translate(`resources.firewalls.fields.VpcId`),
            columnSize: "auto",
            Cell: ({row}: any) => <span>{row?.original?.VpcId}</span>,
        },
        {
            accessor: 'SubnetId',
            Header: translate(`resources.firewalls.fields.SubnetId`),
            columnSize: "auto",
            Cell: ({row}: any) => <span>{row?.original?.SubnetId}</span>,
        },
        {
            accessor: 'Status',
            Header: translate(`resources.firewalls.fields.EndpointStatus`),
            columnSize: "auto",
            Cell: ({row}: any) => {
                if (row?.original?.Status === "REJECTED") {
                    return (<span>{row?.original?.Status} - {row?.original?.RejectedReason}</span>);
                }
                return (<span>{row?.original?.Status}</span>);
            },
        }

    ];

    if (process.env.REACT_APP_ENABLE_EGRESS_NAT === "true") {
        columns.push({
            Header: translate(`resources.firewalls.fields.EgressNAT.PrivateAndPublicTraffic`),
            columnSize: "auto",
            Cell: ({row}: any) => {
                return (
                    <Button appearance="clear"
                            style={{whiteSpace: "break-spaces"}}
                            disabled={formState?.values?.FirewallUpdating || row?.original?.Status !== "ACCEPTED"}
                            onClick={(evt) => handleManageEgressNatTraffic({
                                item: checkEndpointChanges(row?.original),
                                callback: handleCallbackManageTraffic
                            })}
                    >
                        {translate(`resources.firewalls.fields.EgressNAT.ManageTrafficAndEgressNat`)}
                    </Button>
                )
            },
        })
    }

    const endpointsColumns = useMemo(() => [...columns], [formState.values]);

    const handleCallbackManageTraffic = (values: any = {}) => {
        const Features = formState?.values?.Features || {};
        const securityZone = {
            EndpointId: values?.EndpointId,
            Prefixes: values?.Prefixes,
            EgressNATEnabled: values?.EgressNATEnabled
        };
        if (isEmpty(Features)) {
            Features.SecurityZones = [securityZone];
        } else {
            const SecurityZones = Features.SecurityZones || [];
            if (isEmpty(SecurityZones)) {
                SecurityZones.push(securityZone);
            } else {
                const securityZoneIndex = Features.SecurityZones.findIndex((sz:any) => sz.EndpointId === values.EndpointId);
                if (securityZoneIndex === -1) {
                    Features.SecurityZones.push(securityZone);
                } else {
                    Features.SecurityZones[securityZoneIndex] = {
                        ...Features.SecurityZones[securityZoneIndex],
                        ...securityZone
                    }
                }
            }
        }
        form.change('Features', Features);
    };

    return (
        <>
            <Row>
                <Grid item xs={12} sm={6} className="bottomPadding">
                    <FormControl fullWidth>
                        <Field
                            name="Firewall.EndpointServiceName"
                            allowCopy
                            readOnly
                            // @ts-ignore
                            component={PANWDSInput}
                            title={translate('resources.firewalls.fields.EndpointServiceName')}
                            dataMetrics={"cloudngfw-firewall-edit-endpointServiceName"}
                        />
                    </FormControl>
                </Grid>
            </Row>
            <FormControl fullWidth>
                <Field name="Features.SecurityZones">
                    {props => {
                        return (
                            <PanwdsTableLightWithFilters
                                gridData={formState?.values?.Status?.Attachments || []}
                                columns={endpointsColumns}
                                showToolbar={true}
                                showTileTitle={true}
                                title={`${translate(`resources.firewalls.fields.Endpoints`)} (${formState?.values?.Status?.Attachments?.length})`}
                                dataTestId={"cloudngfw-firewall-endpoints-table"}
                                multiSelect={false}
                                searchFilterRequired={true}
                                tableContainerStyle={{padding: '0 1.5rem 0 0'}}
                            />
                        )
                    }}
                </Field>
            </FormControl>
        </>
    );
};

export default Index;
