import '@inovua/reactdatagrid-enterprise/index.css';
import { makeStyles } from '@material-ui/core/styles';
import { isEmpty } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { usePermissions } from '../../customHooks';
import {useHistory, useLocation, useParams, withRouter} from "react-router-dom";
import {
    Button as PANWDSButton,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    TabList,
    TabPanel,
    Tab
} from "@panwds/react-ui";
import * as DataTypes from "../../api/FwaasDataTypes";
import { PANTitle, toast } from '../../components';
import {PANWDSBreadcrumbs} from "../../components/PANWDSElements";
import { dataProvider } from "../../dataProvider";
import { RouteUri } from '../../routeUri';
import { useTranslate } from '../../customHooks';
import { ISplitButtonAction, IValueOptions, ApplicationConfigManager } from '../../types';
import { getStatusIconForArray } from '../../utils/statusIcons';
import { getRuleStackEndpoint, getLogsMetricsEndpoints, getFirewallEndpointLink } from '../../utils/GetUrlLinks';
import {FirewallEditComponent, AssociatedStatusesModalBody} from "./components";

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: '1em',
        border: '1px solid #DADBDB',
        width: '100%',
    },
    media: {
        height: 140,
    },
    header: {
        border: '1px solid #DADBDB',
    },
    title: {
        paddingBottom: '0.5em',
    },
    actionSpacer: {
        display: 'flex',
        justifyContent: 'space-around',
    },
    toolbar: {
        background: "transparent",
        display: "flex",
        gap: theme.spacing(1),
        justifyContent: 'end',
        '-webkit-justify-content': 'flex-end',
        alignItems: 'flex-start',
        boxSizing: 'border-box',
        margin: '1rem',
    },
    indicator: {
        left: 0,
    },
    pantileContainer: {
        display: 'grid',
        gridTemplateColumns: '100%',
        gap: 16,
        margin: 16,
        position: 'relative',
    },
    backdrop: {
        position: "absolute",
        zIndex: 101,
        backgroundColor: "rgba(0, 0, 0, 0.05)",
        overflow: "hidden",
    },
    firewallStatus: {
        width: '100%',
        textAlign: "right",
        "&>button + button": {
            marginLeft: "10px",
        }
    },
    loadingBlur: {
        filter: "blur(1px)",
    },
    tileContent: {
        display: 'grid',
        gridTemplateColumns: '275px calc(100% - 275px)',
        rowGap: 19,
        boxSizing: 'border-box',
        color: '#333333',
        '& div:nth-child(2n+1)': {
            color: '#707070',
        },
        '& i': {
            color: '#707070',
        },
        fontSize: 12,
        lineHeight: "14.4px",
    },
    modalBody: {
        padding: '20px',
        fontSize: 12,
    },
    firewallEditWrapper: {
        position: 'relative',
        height: '100%',
        "&>div:first-child": {
            background: "#fff"
        }
    },
    incompleteTab: {
        color: 'red',
    },
    tabPanelStyles: {
        margin: 0
    },
    pendingColor: {
        color: "var(--light-severity-warning-p-4, var(--orange-40, #F38C16))"
    },
}));

const FirewallEdit = (props: any) => {
    const history = useHistory();
    const [activeTabId, setActiveTabId] = useState("tab1");
    const classes = useStyles();
    const translate = useTranslate();
    const location = useLocation();
    const params = useParams<{ firewallname: string }>();
    const accountId = new URLSearchParams(location.search).get('AccountId');

    const firewallName = params.firewallname;
    const [record, setRecord] = useState<any>(location.state ? location.state : {});

    const [logSettingsData, setLogSettingsData] = useState<any | undefined>(undefined);

    const [loading, setLoading] = useState(!location.state);
    const { permissions } = usePermissions();
    const [openStatusModal, setOpenStatusModal] = useState(false);
    const [ruleStackName, setRuleStackName] = useState(undefined);
    const [globalRuleStackName, setGlobalRuleStackName] = useState(undefined);
    const [ruleStackStatus, setRuleStackStatus] = useState(undefined);
    const [globalRuleStackStatus, setGlobalRuleStackStatus] = useState(undefined);
    const [featureFlags, setFeatureFlags]  = useState({})

    const breadcrumbMapping = {
        ngfirewalls: `${translate('resources.firewalls.name')} [ ${ApplicationConfigManager.getInstance().getConfig().currentRegion.RegionDisplayName} ]`,
        [firewallName]: firewallName,
        [`endpoints`]: translate(`resources.endpoints.name`),
        [`logsettings`]: translate(`resources.logsettings.name`),
        [`rules`]: translate(`resources.ngfwrules.name`),
        [`settings`]: translate(`resources.firewallsettings.name`)
    };

    useEffect(() => {
        if (location.pathname.indexOf("/rules") > 0) {
            setActiveTabId("tab1");
        } else if (location.pathname.indexOf("/settings") > 0) {
            setActiveTabId("tab2");
        } else if (location.pathname.indexOf("/logsettings") > 0) {
            setActiveTabId("tab3");
        } else {
            history.replace(RouteUri.NGFirewallRules.replace(":firewallname", firewallName) + "?AccountId=" + accountId);
        }

    }, [location.pathname, firewallName]);

    useEffect(() => {
        if (isEmpty(record) && permissions?.DescribeFirewall) {
            dataProvider.describe('firewalls', '', { FirewallName: firewallName, AccountId: accountId })
                .then((res: any) => {
                    setLoading(true);
                    if (res.data) {
                        //@ts-ignore
                        setRecord({ ...res.data });
                        setRuleStackName(res?.data?.Firewall?.RuleStackName);
                        setGlobalRuleStackName(res?.data?.Firewall?.GlobalRuleStackName)
                    } else {
                        toast.error(res?.error, { toastId: "firewall-describe" });
                        history.replace(RouteUri.NGFirewallList);
                    }
                    setLoading(false);
                }).catch((e: any) => {
                    toast.error(e?.error, { toastId: "firewall-describe" });
                    history.replace(RouteUri.NGFirewallList);
                });
        }
        getFeatureFlags();
    }, [permissions]);

    const getFeatureFlags = useCallback(() => {
        dataProvider.describe("settings", '', { featureflags: true })
          .then(async (response: DataTypes.IFwaasApiResponse) => {
            if (response.data?.FeatureFlags) {
              setFeatureFlags(response.data.FeatureFlags);
            }
          })
          .catch((e: any) => {
            toast.error(e?.error);
          });
      }, []);

    const describeRulestack = (ruleStackNameAttr: any, ruleStackAttr: 'local' | 'global') => {
        if (permissions?.DescribeRuleStack) {
            dataProvider.describe('ruleStacks', '',
                { RuleStackName: ruleStackNameAttr })
                .then((res: DataTypes.IFwaasApiResponse) => {
                    if (res.data) {
                        if (ruleStackAttr === 'local') {
                            setRuleStackStatus(res?.data?.RuleStackState);
                        }
                        if (ruleStackAttr === 'global') {
                            setGlobalRuleStackStatus(res?.data?.RuleStackState);
                        }

                    } else {
                        toast.error(res?.error, { toastId: `rulestack-describe-${ruleStackNameAttr}` });
                        history.goBack()
                    }
                }).catch((e: any) => {
                    toast.error(e?.error, { toastId: `rulestack-describe-${ruleStackNameAttr}` });
                    history.goBack();
                });
        }
    }

    useEffect(() => {
        if (record?.Firewall?.RuleStackName !== undefined && ruleStackStatus === undefined) {
            describeRulestack(record?.Firewall?.RuleStackName, 'local');
        }
        if (record?.Firewall?.GlobalRuleStackName !== undefined && globalRuleStackStatus === undefined) {
            describeRulestack(record?.Firewall?.GlobalRuleStackName, 'global')
        }
    }, [record]);

    const handleChange = (newValue: string) => {
        //setActiveTabId(newValue);
        let newUrl = "";
        switch (newValue) {
            case "tab1":
                newUrl = RouteUri.NGFirewallRules.replace(":firewallname", firewallName);
                break;
            case "tab2":
                newUrl = RouteUri.NGFirewallSettings.replace(":firewallname", firewallName);
                break;
            case "tab3":
                newUrl = RouteUri.NGFirewallLogSettings.replace(":firewallname", firewallName);
                break;
        }
        history.replace({ pathname: newUrl, search: "?AccountId=" + accountId, state: { ...record } })
    };

    const firewallStatus = record?.Status?.FirewallStatus;
    const firewallCommitStatus = record?.Status?.RuleStackStatus;
    const deviceRuleStackCommitStatus = record?.Status?.DeviceRuleStackCommitStatus;
    const endpointStatus = record?.Status?.Attachments[0]?.Status;
    const endpointLength = record?.Status?.Attachments?.length;
    const scmStatus = record?.Status?.SCMAssocStatus === true ? translate(`resources.overview.associated`) :  translate(`resources.overview.notAssociated`);

    const openModal = (clicked: boolean) => {
        setOpenStatusModal(clicked);
    }

    const getRuleStackValues = () => {
        const ruleStackVal: IValueOptions[] = [];
        if (record?.Firewall?.RuleStackName) {
            ruleStackVal.push({
                text: record?.Firewall?.RuleStackName + " (Local)",
                isLink: true,
                linkToOpen: getRuleStackEndpoint(record?.Firewall?.RuleStackName),
                linkOption: 'internal',
            })
        }
        if (record?.Firewall?.GlobalRuleStackName) {
            ruleStackVal.push({
                text: record?.Firewall?.GlobalRuleStackName + " (Global)",
                isLink: true,
                linkToOpen: getRuleStackEndpoint(record?.Firewall?.GlobalRuleStackName),
                linkOption: 'internal',
            })
        }
        return ruleStackVal;
    }

    const getStatusText = () => {
        const statusEval = getStatusIconForArray([firewallStatus, firewallCommitStatus, endpointStatus, ruleStackStatus]);
        let status = translate("resources.firewalls.overviewPanel.review")
        if (statusEval !== 'Success') {
            status = translate("resources.firewalls.overviewPanel.needsReview")
        }
        return [status, statusEval];
    }

    const dropDownActionsArray: ISplitButtonAction[] = [
        { menuText: translate("resources.firewalls.dropdown.logsTraffic"), progressText: translate("resources.firewalls.dropdown.logsTraffic"), handleAction: () => getLogsMetricsEndpoints(logSettingsData?.LogType?.includes('TRAFFIC') ? logSettingsData?.TRAFFIC.LogDestinationType : '', logSettingsData?.LogType?.includes('TRAFFIC') ? logSettingsData?.TRAFFIC.LogDestination : '', 'Logs'), enabled: logSettingsData?.LogType?.includes('TRAFFIC') !== undefined && logSettingsData?.LogType?.includes('TRAFFIC') !== false },
        { menuText: translate("resources.firewalls.dropdown.logsThreat"), progressText: translate("resources.firewalls.dropdown.logsThreat"), handleAction: () => getLogsMetricsEndpoints(logSettingsData?.LogType?.includes('THREAT') ? logSettingsData?.THREAT.LogDestinationType : '', logSettingsData?.LogType?.includes('THREAT') ? logSettingsData?.THREAT.LogDestination : '', 'Logs'), enabled: logSettingsData?.LogType?.includes('THREAT') !== undefined && logSettingsData?.LogType?.includes('THREAT') !== false },
        { menuText: translate("resources.firewalls.dropdown.logsDecryption"), progressText: translate("resources.firewalls.dropdown.logsDecryption"), handleAction: () => getLogsMetricsEndpoints(logSettingsData?.LogType?.includes('DECRYPTION') ? logSettingsData?.DECRYPTION.LogDestinationType : '', logSettingsData?.LogType?.includes('DECRYPTION') ? logSettingsData?.DECRYPTION.LogDestination : '', 'Logs'), enabled: logSettingsData?.LogType?.includes('DECRYPTION') !== undefined && logSettingsData?.LogType?.includes('DECRYPTION') !== false },
        { menuText: translate("resources.firewalls.dropdown.metrics"), progressText: translate("resources.firewalls.dropdown.metrics"), handleAction: () => getLogsMetricsEndpoints(logSettingsData?.CloudWatchNamespace, '', 'Metrics'), enabled: logSettingsData?.CloudWatchNamespace !== undefined }
    ];

    const getOverviewPanelList = () => {
        const panelDetails = [{ key: translate("resources.firewalls.overviewPanel.firewallId"), values: [{ text: record?.Firewall?.FirewallName, isNormalText: true }], isLoading: loading },
            { key: translate("resources.firewalls.overviewPanel.awsAccountId"), values: [{ text: record?.Firewall?.AccountId, isNormalText: true }], isLoading: loading },
            { key: translate("resources.firewalls.overviewPanel.endpointMode"), values: [{ text: record?.Firewall?.EndpointMode, isNormalText: true }], isLoading: loading },
            { key: translate("resources.firewalls.overviewPanel.status"), values: [{ text: getStatusText(), isStatusLink: true, linkOption: 'modal' }], openModal: openModal, isLoading: loading },
            { key: translate("resources.firewalls.overviewPanel.rulestacks"), values: getRuleStackValues(), isLoading: loading },
            { key: translate("resources.firewalls.overviewPanel.awsConsoleLinks"), values: [{ text: translate("resources.firewalls.overviewPanel.logMetric"), isDropdownLink: true, linkOption: 'dropdown', dropDownActionsArray: dropDownActionsArray }], isLoading: loading },
            { key: translate("resources.firewalls.overviewPanel.endpoints"), values: [{ text: endpointLength, isLink: endpointLength > 0, isNormalText: endpointLength === 0, linkToOpen: getFirewallEndpointLink(firewallName, accountId), linkOption: 'internal' }], isLoading: loading },
        ]

        const scmIndex = 3; // Index for the 4th position

        if (record?.Firewall?.LinkId && record?.Firewall?.LinkId.startsWith("Link-SCM")) {
            panelDetails.splice(scmIndex, 0,
                {key: translate("resources.firewalls.overviewPanel.strataCloudManagerAssociation"), values: [{ text: scmStatus, isStatus: true }], isLoading: loading },
            );
        }

        return panelDetails;
    }

    return (
        <>
            <PANTitle divider={false} />
            <PANWDSBreadcrumbs
                mapping={breadcrumbMapping}
                queryParams={"?AccountId=" + accountId}
            />
            <PANTitle
                title={firewallName}
                overviewPanel={getOverviewPanelList()}
            />
            <Modal
                onClose={() => setOpenStatusModal(false)}
                isOpen={openStatusModal}
                size="md">
                <ModalHeader
                    title={translate("resources.firewalls.modal.statuses")}
                    enableClose={true}
                />
                <ModalBody>
                    <AssociatedStatusesModalBody
                        ruleStackName={record?.Firewall?.RuleStackName ? record?.Firewall?.RuleStackName : record?.Firewall?.GlobalRuleStackName}
                        firewallStatus={firewallStatus}
                        firewallCommitStatus={firewallCommitStatus}
                        deviceRuleStackCommitStatus={deviceRuleStackCommitStatus}
                        endpointStatus={endpointStatus}
                        ruleStackStatus={ruleStackStatus}
                        globalRuleStackStatus={globalRuleStackStatus}
                    />
                </ModalBody>
                <ModalFooter>
                    <PANWDSButton
                        size="md"
                        appearance="secondary"
                        onClick={() => setOpenStatusModal(false)}
                        dataMetrics="cloudngfw-firewall-edit-modal-status-close"
                    >
                        {translate("resources.firewalls.modal.close")}
                    </PANWDSButton>
                </ModalFooter>
            </Modal>

            <div className={classes.firewallEditWrapper}>
                <TabList
                    activeTabId={activeTabId}
                    onActiveChange={(nextTabId) => handleChange(nextTabId)}
                    disabled={loading}
                >
                    <Tab id={"tab1"}>Rules</Tab>
                    <Tab id={"tab2"}>Firewall Settings</Tab>
                    <Tab id={"tab3"}>Log Settings</Tab>
                </TabList>

                <TabPanel addClassName={classes.tabPanelStyles} activeTabId={activeTabId} forTabId={"tab1"}>
                    {record?.Firewall && <FirewallEditComponent.RulesSettings record={record}/>}
                </TabPanel>

                <TabPanel addClassName={classes.tabPanelStyles} activeTabId={activeTabId} forTabId={"tab2"}>
                    <FirewallEditComponent.FirewallSettings record={record} classes={classes} featureFlags={featureFlags}/>
                </TabPanel>

                <TabPanel addClassName={classes.tabPanelStyles} activeTabId={activeTabId} forTabId={"tab3"}>
                    <FirewallEditComponent.LogSettings classes={classes} record={record} />
                </TabPanel>
            </div>
        </>
    );
};
export default withRouter(FirewallEdit);
